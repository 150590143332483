<template>
  <div class="score-query">
    <el-row class="score-query-title">
      <el-col>
        <div style="position: relative">
          <el-icon
            class="btn"
            v-show="!pagesStatus.messageInfoStatus"
            @click="$router.push('/protal')"
            ><ArrowLeftBold
          /></el-icon>
          <el-icon
            class="btn"
            v-show="pagesStatus.messageInfoStatus"
            @click="back"
            ><ArrowLeftBold
          /></el-icon>
        </div>
        系统公告</el-col
      >
    </el-row>
    <el-row class="message-content" v-if="pagesStatus.messageInfoStatus">
      <el-col><div v-html="state.infoContent"></div></el-col>
    </el-row>
    <el-row v-else-if="state.errorMessage">
      <el-col
        ><div class="errorMessage">{{ state.errorMessage }}</div></el-col
      >
    </el-row>
    <el-row v-loading="pagesStatus.loading" class="message-content" v-else>
      <el-col>
        <div style="height: 75vh; overflow: hidden">
          <div
            class="message-item"
            v-for="(item, index) in rowsList"
            :key="index"
            @click="messageInfoStatusChange(item)"
          >
            <div class="message-item-left">
              <div>
                {{ item.title }}
              </div>
              <div class="text">
                {{ item.brief }}
              </div>
              <div >
                <span style="float: right;">{{ item.startTime }} ~ {{ item.endTime }}</span>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total"
          style="float: right"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { post, get } from "@/api/api";
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { ArrowLeftBold } from "@element-plus/icons-vue";
onMounted(async () => {
  getNoticeList();
});
// data
// 分页数据
let pagination = reactive({
  pageSize: 4,
  pageNo: 1,
  total: 0,
});
// 页面状态
let pagesStatus = reactive({
  messageInfoStatus: false,
  loading: true
});
// 数据
let state = reactive({
  infoContent: '',
  errorMessage:''
});
// methods
// 获取所有系统公告
const getNoticeList = async () => {
  let res = await get("/examApply/noticeList", { pageSize: pagination.pageSize, pageNo: pagination.pageNo });
  if (res.code === 200) {
    pagesStatus.loading = false
    rowsList.value = res.result.list;
    pagination.total = res.result.total
    if (res.result.list.length == 0) {
      state.errorMessage = res.message
    } else {
      state.errorMessage = ''
    }
  } else {
    ElMessage.error(res.message)
  }

};

// 分页切换
const handleCurrentChange = (num) => {
  pagination.pageNo = num;
  getNoticeList()
};
// 点击公告
const messageInfoStatusChange = async(row) => {
  pagesStatus.loading=true
  let res = await get("/examApply/notice/queryById", {
    id: row.id
  });
  if (res.code === 200) {
    state.infoContent = res.result.content
    pagesStatus.loading=false
    pagesStatus.messageInfoStatus = true;
  } else {
    ElMessage.error(res.message);
    pagesStatus.loading=false
  }
};
// 返回
const back = () => {
  pagesStatus.messageInfoStatus = false;
};
// list数据
let rowsList = ref([]);

</script>
<style lang="scss" scoped>
.score-query {
  height: 89vh;
  overflow: hidden;
  background-color: #ffffff;
}
.message-content {
  overflow-y: auto;
  height: calc(89vh - 0.4167rem);
  padding: 0 60px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.score-query-title {
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-size: 0.1563rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0.1042rem;
  .btn {
    position: absolute;
    left: 0;
    width: 60px;
    height: 60px;
    font-size: 0.0833rem;
    cursor: pointer;
  }
}
.message-item {
  display: flex;
  justify-content: space-between;
  height: 16vh;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  margin-bottom: 20px;
  cursor: pointer;
  padding-left: 20px;
  border-left: 4px solid #097afa;

  .message-item-left {
    width: 98%;
    display: flex;
    align-content: center;
    justify-content: space-around;
    flex-direction: column;

    div {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      margin: 6px 0;
    }
    .text {
      font-size: 0.0729rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    div:nth-child(3) span {
      font-size: 0.0729rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
    }
  }
}
.message-item:nth-child(4) {
  margin-bottom: 10px;
}
.message-item-disabled {
  border-left: 4px solid #d2d2d2;
}
.message-item:hover {
  background-color: #eee;
}
.el-button {
  margin: 0;
  background-color: #097afa;
}
.is-disabled,
.is-disabled:hover {
  border: none;
  color: #999999;
  background-color: #eeeeee;
}
.errorMessage {
  height: 3.6979rem;
  line-height: 3.6979rem;
  font-size: 24px;
  color: #ccc;
  text-align: center;
}
</style>